import React from 'react';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { productDefaultImage } from '../../../constants/productDefaultImage';
import OtherImage from '../../ImagesComponent/OtherImage';
import { generateClassName } from '../../../utils/generateClassName';
import { IPurchaseReviewItem } from '../../../typings/IPurchaseReviewItem';
import { IReview } from '../../../typings/IReview';
import { buildLocale } from '../../../utils/buildLocale';
import config from '../../../config';
import { Link } from 'react-router-dom';
import { usePaths } from '../../Routes/RouterList';
import ReviewStars from '../../Products/ProductReviews/ReviewStars';

interface IClientPurchaseItemProps {
  item: IPurchaseReviewItem;
}

const PurchaseReviewItem = (props: IClientPurchaseItemProps) => {
  const { item } = props;
  const paths = usePaths();
  const currentTranslate = useSelector(selectorsLocale.getTranslate);

  const getProductImage = (item: IPurchaseReviewItem) => {
    const cdnImages = item.product.cdnImages;
    return cdnImages.at(0) ? cdnImages.at(0) : productDefaultImage;
  };

  const image = getProductImage(item);

  return (
    <div className="profile-product-review_history-item">
      <div className="inner">
        <figure className="pic">
          <OtherImage src={image?.variants['250x150']} title={item.product.title} width="60" height="60" />
        </figure>
        <div className="info">
          <div className="info-left">
            <Link
              target="_blank"
              className={generateClassName('info-name', { inactive: !item.product.isActive })}
              to={`${config.canLink}${paths.product(item.product.id)}`}>
              {item.product.title}
            </Link>
            {!!item.product.reviews?.length &&
              item.product.reviews.map((review: IReview) => (
                <div className="comment">
                  <div>
                    <ReviewStars
                      isEditable={false}
                      ratingValue={review.rating}
                      additionalData={
                        <span className="product-reviews_bottom_head_rate">
                          {Number.parseFloat(review.rating?.toString() || '0')}
                          <span>/5</span>
                        </span>
                      }
                    />
                  </div>
                  <p>{review.text}</p>
                </div>
              ))}
            {!item.product.reviews?.length && item.product.isActive && (
              <Link
                target="_blank"
                className="btn btn-blue-border leave-review"
                to={`${config.canLink}${paths.product(item.product.id)}?ordered_at=${item.order.id}#reviews`}>
                {buildLocale(currentTranslate, 'profileReviewsHistoryLeaveReview')}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseReviewItem;
