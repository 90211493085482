import React, { useState } from 'react';
import useDidUpdateEffect from '../../../useHooks/useDidUpdateEffect';
import { useFormattingContext } from '../../../context/FormattingContext';
import { FRACTION_DIGIT } from '../../../constants/constantsId';
import { useSelector } from 'react-redux';
import { selectorsLocale } from '../../../redux/locale/localeReducer';
import { buildLocale } from '../../../utils/buildLocale';
import { selectorsCart } from '../../../redux/cart/cartReducer';

interface ICountInputProps {
  isWeightUnit: boolean;
  onChange: (o: number) => any;
  value: number;
  isEditable?: boolean;
  isRemove?: boolean;
  max?: number;
}

const compateFloat = (a: number, b: number, c: number) => {
  return a - b <= c;
};

const CountInput = React.memo(
  ({ isWeightUnit, onChange, value, isEditable = true, isRemove = false }: ICountInputProps) => {
    const { formatUnit } = useFormattingContext();
    const currentTranslate = useSelector(selectorsLocale.getTranslate);
    const min = isWeightUnit ? 0.01 : 1;
    const step = isWeightUnit ? 0.1 : 1;
    const isLoadingCartRequest = useSelector(selectorsCart.getIsLoading);

    const [count, setCount] = useState(value);
    const [isEdit, setIsEdit] = useState(false);

    const handleChange = (e) => {
      const value = e.target.value;
      if (!isWeightUnit && value.search(/\./) !== -1) {
        return;
      }
      const n = +value;
      if (isNaN(n)) return;
      let res = value.match(/\.\d+/g);
      if (res && res[0].length > FRACTION_DIGIT + 1) return;
      setCount(value);
    };

    const handleBlur = () => {
      setIsEdit(false);
      let newValue = +count;
      if (newValue < min) {
        onChange(min);
        newValue = min;
      } else {
        onChange(newValue);
      }
      setCount(newValue);
    };

    const incr = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (Math.fround(count) < 0.1) {
        setCount((c) => {
          const res = c + 0.01;
          onChange(res);
          return res;
        });
      } else {
        setCount((c) => {
          const res = c + step;
          onChange(res);
          return res;
        });
      }
    };

    const decr = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (compateFloat(count, 0.1, 0.01)) {
        if (compateFloat(count, 0.01, 0.001)) {
          if (isRemove) {
            onChange(0);
          }
          return;
        }
        setCount((c) => {
          const res = c - 0.01;
          onChange(res);
          return res;
        });
      } else {
        if (count - step <= 0) {
          if (isRemove) {
            onChange(0);
          }
          return;
        }

        setCount((c) => {
          const res = c - step;
          onChange(res);
          return res;
        });
      }
    };

    const handleEdit = () => {
      if (isEditable) {
        setCount((c) => {
          return +c.toFixed(FRACTION_DIGIT);
        });
        setIsEdit(true);
      }
    };

    useDidUpdateEffect(() => {
      setCount(value);
    }, [value]);

    return isWeightUnit ? (
      <div className="weight-choose_form quantity-form_inside">
        {isEditable && (
          <button type="button" className="prev" onClick={decr} disabled={isLoadingCartRequest}>
            &nbsp;
          </button>
        )}
        {isEdit ? (
          <input key={'input'} type="text" value={count} autoFocus onBlur={handleBlur} onChange={handleChange} />
        ) : (
          <input
            key={'input'}
            type="text"
            onClick={handleEdit}
            contentEditable={false}
            value={formatUnit(count, `${buildLocale(currentTranslate, 'weight')}`)}
            onChange={(e) => null}
          />
        )}
        {isEditable && (
          <button type="button" className="next" onClick={incr} disabled={isLoadingCartRequest}>
            &nbsp;
          </button>
        )}
      </div>
    ) : (
      <div className="weight-choose_form quantity-form_inside">
        {isEditable && (
          <button type="button" className="btn btn-minus" onClick={decr} disabled={isLoadingCartRequest}>
            -
          </button>
        )}
        {isEdit ? (
          <input key={'input'} type="text" value={count} autoFocus onBlur={handleBlur} onChange={handleChange} />
        ) : (
          <input
            key={'input'}
            type="text"
            onClick={handleEdit}
            contentEditable={false}
            value={formatUnit(count, 'other')}
            onChange={(e) => null}
          />
        )}
        {isEditable && (
          <button type="button" className="btn btn-plus" onClick={incr} disabled={isLoadingCartRequest}>
            +
          </button>
        )}
      </div>
    );
  },
);

export default CountInput;
